exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx?export=default" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-default-template-tsx": () => import("./../../../src/templates/default.template.tsx?export=default" /* webpackChunkName: "component---src-templates-default-template-tsx" */),
  "component---src-templates-products-template-tsx": () => import("./../../../src/templates/products.template.tsx?export=default" /* webpackChunkName: "component---src-templates-products-template-tsx" */)
}



exports.head = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx?export=head" /* webpackChunkName: "component---src-pages-404-tsxhead" */),
  "component---src-templates-default-template-tsx": () => import("./../../../src/templates/default.template.tsx?export=head" /* webpackChunkName: "component---src-templates-default-template-tsxhead" */),
  "component---src-templates-products-template-tsx": () => import("./../../../src/templates/products.template.tsx?export=head" /* webpackChunkName: "component---src-templates-products-template-tsxhead" */)
}

